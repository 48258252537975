import Swiper, { Pagination } from 'swiper';

export default class Slider {
  constructor(element) {
    this.element = element;
    this.swiperElement = this.element.querySelector('[data-slider-swiper]');
    this.slides = this.element.querySelectorAll('[data-slider-slide]');
    this.pagination = this.element.querySelector('[data-slider-pagination]');
    this.settings = {
      loop: false,
      // freeMode: true,
      slidesPerView: 'auto',
      pagination: { el: this.pagination, clickable: true },
    };
    this.swiper = null;
  }

  mount() {
    Swiper.use([Pagination]);

    this.swiper = new Swiper(this.swiperElement, this.settings);
  }

  unmount() {
    this.destroy(this.swiper, true);
  }
}
