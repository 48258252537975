export default class Newsletter {
  constructor(element) {
    this.element = element;
    this.form = this.element.querySelector('[data-newsletter-form]');
    this.feedback = this.element.querySelector('[data-newsletter-feedback]');
    this.email = this.form.querySelector('[data-newsletter-email]');
    this.email_feedback = this.form.querySelector(`#${this.email.getAttribute('aria-describedby')}`);
    this.gdpr = this.form.querySelector('[data-newsletter-gdpr]');
    this.gdpr_feedback = this.form.querySelector(`#${this.gdpr.getAttribute('aria-describedby')}`);
    this.is_processing = false;

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleFormSubmit (event) {
    event.preventDefault();

    if (!this.is_processing) {
      this.is_processing = true;

      this.feedback.classList.remove('u-color-success');
      this.feedback.classList.remove('u-color-error');

      this.email.classList.remove('is-invalid');
      this.email_feedback.classList.remove('is-invalid');
      this.email_feedback.innerHTML = "";

      this.gdpr.classList.remove('is-invalid');
      this.gdpr_feedback.classList.remove('is-invalid');
      this.gdpr_feedback.innerHTML = "";

      const data = new URLSearchParams();
      for (const pair of new FormData(this.form)) {
        data.append(pair[0], pair[1]);
      }

      fetch(this.form.action, {
        method:'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-Requested-With': 'XMLHttpRequest'
        },
        body: data,
      })
        .then((response) => {
          if (!response.ok && response.status !== 400) {
            throw Error(response.statusText);
          }

          return response.json();
        })
        .then((json) => {
          if (!json.success) {
            this.invalid(json)
          } else {
            this.success();
          }
          this.is_processing = false;
        })
        .catch((error) => {
          this.error();
          this.is_processing = false;
        });
    }
  }

  success () {
    this.form.innerHTML = '';
    this.feedback.classList.add('u-color-success');
    this.feedback.innerHTML = `<p>${this.element.dataset.newsletterSuccess}</p>`;
  }

  invalid (json) {
    const data = json.data;

    if (data.error) {
      this.email.classList.add('is-invalid');
      this.email_feedback.classList.add('is-invalid');
      this.email_feedback.innerHTML = this.email_feedback.dataset.newsletterFeedback;
    }

    if (data.gdpr_error) {
      this.gdpr.classList.add('is-invalid');
      this.gdpr_feedback.classList.add('is-invalid');
      this.gdpr_feedback.innerHTML = this.email_feedback.dataset.newsletterFeedback;
    }
  }

  error () {
    this.feedback.classList.add('u-color-error');
    this.feedback.innerHTML = `<p>${this.element.dataset.newsletterError}</p>`;
  }

  mount () {
    this.form.addEventListener('submit', this.handleFormSubmit);
  }

  unmount () {
    this.form.removeEventListener('submit', this.handleFormSubmit);
  }
}
