import 'mdn-polyfills/NodeList.prototype.forEach';
import { debounce } from '../../utilities/utilities';
import Toggler from '../toggler/Toggler';
import Navigation from '../navigation/Navigation';

export default class Header {
  constructor(element) {
    this.element = element;
    this.top = this.element.querySelector('#header-top');
    this.main = this.element.querySelector('#header-main');
    this.navigationTogglers = [];
    this.height = this.element.offsetHeight;
    this.tmp = 0;
    this.navigation = null;
    this.navigationToggler = null;
    this.hasOverlay = false;
    this.countOverlay = 0;
    this.isMounted = false;

    this.handleNavigationHide = this.handleNavigationHide.bind(this);
    this.handleNavigationShow = this.handleNavigationShow.bind(this);
    this.handleNavigationUpdate = this.handleNavigationUpdate.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleDebouncedResize = debounce(this.handleDebouncedResize.bind(this), 100);
  }

  handleNavigationHide() {
    this.element.classList.remove('has-navigation-active');

    this.navigation.togglers.forEach((toggler) => {
      toggler.hide(true);
    });

    this.navigationToggler.focus();
    this.navigationToggler = null;
    this.update();
  }

  handleNavigationShow(e) {
    this.navigationToggler = e.detail.toggler.element;
    this.element.classList.add('has-navigation-active');
    this.update();
  }

  handleNavigationUpdate() {
    this.update();
  }

  handleScroll() {
    if (document.documentElement.scrollTop > 20) {
      this.element.classList.add('is-pinned');
      this.calculate();
    } else {
      this.element.classList.remove('is-pinned');
      this.calculate();
    }
  }

  handleDebouncedResize() {
    if (this.tmp !== this.element.offsetWidth) {
      this.height = this.element.offsetHeight;
      this.navigationTogglers.forEach((toggler) => {
        const isTogglable = window.getComputedStyle(toggler.element).getPropertyValue('display') !== 'none';

        if (toggler.mounted !== isTogglable) {
          if (isTogglable) {
            toggler.mount();
          } else {
            toggler.unmount();
          }
        }
      });

      this.tmp = this.element.offsetWidth;
      this.calculate();
    }
  }

  calculate() {
    if(this.isMounted) {
      this.height = Math.max(this.height, this.element.offsetHeight);
      document.documentElement.style.setProperty('--header-height', `${this.height}px`);
    }
  }

  update() {
    let isNavigationHasTogglerExpanded = false;

    this.navigation.togglers.forEach((toggler) => {
      if (!toggler.target.contains(toggler.element)) {
        isNavigationHasTogglerExpanded = toggler.expanded === true ? true : isNavigationHasTogglerExpanded;
      }
    });
  }

  mount() {
    this.element.querySelectorAll('[data-header-toggler="navigation"]').forEach((element) => {
      const target = document.getElementById(element.dataset.headerToggler);
      const toggler = new Toggler(element, target);
      this.navigationTogglers.push(toggler);
      element.addEventListener('toggler:hide', this.handleNavigationHide);
      element.addEventListener('toggler:show', this.handleNavigationShow);

      if (window.getComputedStyle(element).getPropertyValue('display') !== 'none') {
        toggler.mount();
      }
    });

    const nav = this.element.querySelector('#navigation');
    if (nav) {
      this.navigation = new Navigation(nav);
      this.navigation.element.addEventListener('navigation:update', this.handleNavigationUpdate);
      this.navigation.mount();
    }

    window.addEventListener('resize', this.handleDebouncedResize);
    window.addEventListener('scroll', this.handleScroll);
    this.handleDebouncedResize();
    this.handleScroll();

    this.element.classList.add('is-mounted');
    this.isMounted = true;
  }

  unmount() {
    window.removeEventListener('resize', this.handleDebouncedResize);
    window.removeEventListener('scroll', this.handleScroll);

    this.navigation.unmount();
    this.navigation.element.removeEventListener('navigation:update', this.handleNavigationUpdate);
    this.navigationTogglers.forEach((toggler) => {
      toggler.element.removeEventListener('toggler:hide', this.handleNavigationHide);
      toggler.element.removeEventListener('toggler:show', this.handleNavigationShow);
      toggler.unmount();
    });

    this.element.classList.remove('is-mounted');
    this.isMounted = false;
  }
}
