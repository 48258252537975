import 'mdn-polyfills/NodeList.prototype.forEach';
import { debounce } from '../../utilities/utilities';

export default class Summary {
  constructor(element) {
    this.element = element;
    this.links = this.element.querySelectorAll('[data-summary-link]');
    this.targets = [];
    this.current = null;
    this.size = null;
    this.hasScrolled = null;
    this.hasResized = null;

    this.handleScroll = this.handleScroll.bind(this);
    this.handleDebouncedResize = debounce(this.handleDebouncedResize.bind(this), 100);
  }

  handleScroll() {
    this.hasScrolled = true;
  }

  handleDebouncedResize() {
    this.hasScrolled = true;
  }

  check() {
    let current = null;
    this.targets.forEach((target) => {
      const bounds = target.getBoundingClientRect();
      const link = this.element.querySelector(`[data-summary-link][href="#${target.getAttribute('id')}"]`);

      if (
        bounds.y < this.size.y / 2
        && bounds.y + bounds.height > this.size.y / 2
      ) {
        link.classList.add('is-active');
        current = link;
      } else {
        link.classList.remove('is-active');
      }
    });

    this.current = current;
  }

  mount() {
    const update = () => {
      if (this.hasScrolled) {
        this.check();
        this.hasScrolled = false;
      }

      if (this.hasResized) {
        this.size = { x: window.innerWidth, y: window.innerHeight };
        this.hasResized = false;
      }

      requestAnimationFrame(update);
    };

    this.links.forEach((link) => {
      const target = document.getElementById(link.getAttribute('href').replace('#', ''));
      this.targets.push(target);
    });

    this.size = { x: window.innerWidth, y: window.innerHeight };
    this.check();

    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleDebouncedResize);
    update();
  }

  unmount() {
    this.hasScrolled = null;
    this.hasResized = null;
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleDebouncedResize);
  }
}
