import 'mdn-polyfills/NodeList.prototype.forEach';
import Summary from './Summary';

document.addEventListener('DOMContentLoaded', () => {
  const summaries = document.querySelectorAll('[data-summary]');
  if (!summaries.length) {
    return;
  }
  summaries.forEach((element) => {
    const summary = new Summary(element);
    summary.mount();
  });
});
